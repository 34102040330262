<template>
  <b-card>
    <!-- form -->
    <b-form>

      <b-row>
        <!-- full namee -->
        <b-col md="6">
          <b-form-group
            label="Full Name"
            label-for="fullname"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="fullname"
                v-model="userFullName"
                name="fullname"
                type="text"
                placeholder="Full Name"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ full name -->

        <!-- username -->
        <b-col md="6">
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="username"
                v-model="username"
                name="username"
                type="text"
                :disabled="true"
                :placeholder="username"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ username -->
      </b-row>

      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label="Old Password"
            label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                :type="passwordFieldTypeOld"
                placeholder="Old Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>

      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            label="New Password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="Retype New Password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="sendData()"
          >
            Save changes
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
      
    </b-form>
  </b-card>
</template>

<script>
import store from "@/store/index";
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  mounted: function() {

    const userData = store.state.app.userData;
    const token = `${userData.token_type} ${userData.accessToken}`;

    this.userFullName = userData.name;
    this.username = userData.username;

  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      userFullName: "",
      username: ""
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    sendData() {

      let data = {
        "name": ""
      }

      if(this.userFullName == "" || this.userFullName == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
            "Full name must not be empty!",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }

      data.name = this.userFullName;

      // if any of the password fields are not empty, check if they are all filled
      if(this.passwordValueOld != "" || this.newPasswordValue != "" || this.RetypePassword != "") {
        
        // check if all fields are not empty
        if(this.passwordValueOld != "" && this.newPasswordValue != "" && this.RetypePassword != "") {        

          // check if the new password and password retype match
          // if they match, set the values
          if(this.newPasswordValue == this.RetypePassword) {
            data.password = this.newPasswordValue;
            data.c_password = this.RetypePassword;
            data.current_password = this.passwordValueOld;
          } else {
            this.$toast({
            component: ToastificationContent,
              props: {
                title: "New password and password retype don't match",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
            return;
          }

        } else {
          // not all fields are filled
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "All password fields are required when changing your password!",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          return;
        }
      }
      
      const userData = store.state.app.userData;
      const token = `${userData.token_type} ${userData.accessToken}`;

      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      this.$http.patch("/api/auth/user", data, requestOptions)
        .then((res) => {
          const userData = res.data.user;
          try {
            let name = userData.name.split(" ").join("+");
            let user_image = "https://eu.ui-avatars.com/api/?name=" + name;
            userData.avatar = user_image;
          } catch (err) {
            userData.avatar = "";
          }
          store.commit("app/setUserData", userData);

          this.$toast({
            component: ToastificationContent,
            props: {
              title:
              "Profile info successfully updated !",
                icon: "CheckSquareIcon",
                variant: "success",
            },
          });

          this.RetypePassword = "";
          this.newPasswordValue = "";
          this.passwordValueOld = "";

        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });

    }
  },
}
</script>
